import { observable, action, makeObservable } from 'mobx';
import Cookies from 'universal-cookie';

import request from '~/bin/httpRequest';
import analytics from '~/helpers/analytics';
import { setValueToIframe } from '~/helpers/multisesion';

export const cookies = new Cookies();

export const guestSession = () => {
  const options = {
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/guestsession`,
    loading: false,
  };

  return request.genericHandler(options, null).then((res) => {
    let callback = { action: 'guestSession', success: false };
    if (!res.error) {
      callback = { ...callback, data: res.data, success: true };
    } else {
      callback = { ...callback, error: res.error };
    }
    return callback;
  });
};

export const modalResetPassword = (e) => {
  e.preventDefault();
  const el = e.target.closest('.reset-password');
  if (el && e.currentTarget.contains(el)) {
    const modal = document.querySelector('.modal');
    modal.classList.add('active');
    document.querySelector('.modal--user').classList.add('active');
    modal.style.display = 'block';
    setTimeout(() => { document.querySelector('.trigger-password').click(); }, 1);
  }
};

class SessionStore {
  userSession = observable.map();

  userGuest = true;

  userLogin = false;

  userLoginInfo = false;

  person = observable.map();

  constructor() {
    makeObservable(this, {
      userSession: observable,
      userGuest: observable,
      userLogin: observable,
      userLoginInfo: observable,
      person: observable,
      guestSession: action,
      personRegister: action,
      personLogin: action,
      personLogout: action,
      syncAddresses: action,
      userInfo: action
    });
  }

  guestSession() {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/guestsession`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'guestSession', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = { ...callback, data: res.data, success: true };
        this.userSession = callback;
      } else {
        callback = { ...callback, error: res.error };
        this.userSession = callback;
      }
      return callback;
    });
  }

  personRegister(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_CUSTOM_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'personRegister', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = { ...callback, data: res.data, success: true };
        this.person = callback;
        this.userSession = callback;
        setValueToIframe(res.data.data.token);
        analytics.hashUserId(query.email);
      } else {
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = { ...callback, error: res.error };
        this.userSession = callback;
      }

      return callback;
    });
  }

  personLogin(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_CUSTOM_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/login`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'personLogin', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = { ...callback, data: res.data, success: true };
        this.person = callback;
        this.userSession = callback;
        setValueToIframe(res.data.data.token);
        analytics.hashUserId(res.data.data.email);
        analytics.login();
      } else {
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = { ...callback, error: res.error };
        this.userSession = callback;
      }
      return callback;
    });
  }

  personLogout() {
    const options = {
      method: 'delete',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/login`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'personLogout', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = { ...callback, data: res.data, success: true };
        this.person = callback;
        this.userSession = callback;
        setValueToIframe('');
        analytics.logout();
        cookies.remove('Session-Id', { path: '/' });
        window.location.href = '/';
      } else {
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = { ...callback, error: res.error };
        this.userSession = callback;
      }
      return callback;
    });
  }

  syncAddresses() {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/syncaddresses`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'syncAddresses', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = { ...callback, data: res.data, success: true };
        this.userSession = callback;
      } else {
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = { ...callback, error: res.error };
        this.userSession = callback;
      }
      return callback;
    });
  }

  userInfo() {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/guestinfo`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'userInfo', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = { ...callback, data: res.data, success: true };

        this.userSession = callback;
      } else {
        callback = { ...callback, error: res.error };
        this.userSession = callback;
      }
      return callback;
    });
  }
}

export default new SessionStore();
